import { CountryLanguageCode } from './../../modals/commonsetting.modal';
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { SidenavMenu } from "../shared/sidebar/sidebar-menu.model";
import { UserService } from "../shared/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "../shared/services/config.service";
import { ConfirmDialogComponent, ConfirmDialogModel } from "../shared/model/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { RestApiService } from "../shared/services/restapi.service";
import { MatSidenav } from "@angular/material/sidenav";
import { Cart1Service } from "../shared/services/cart1.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UtilityService } from "../shared/services/utility.service";
import { JoiningdialogComponent } from "../shared/joiningdialog/joiningdialog.component";
import { ForgotDialogComponent } from "../shared/forgot-dialog/forgot-dialog.component";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public token='';
  public allowedCountries: any;
  content_display: string;
  public customerTypes: any;
  public country: any;
  userServiceModal: UserServiceModal;
  products: Array<DSProduct>;
  welcmtext: string = "";
  memberTypes = [];
  public url: any;
  selectedCustomerType: any;
  navItems: SidenavMenu[] = [
    {
      displayName: "Shop Now",
      iconName: "shop now",
      route: "/shopnow",
    },
    {
      displayName: "Loyalty Rewards",
      iconName: "loyalty rewards",
      route: "/loyalty-rewards",
    },
    {
      displayName: "Contact Us",
      iconName: "contact us",
      route: "/contact",
    },
    {
      displayName: "Log Out",
      iconName: "feedback",
      route: "/home",
    },
    {
      displayName: "Log In",
      iconName: "settings",
      route: "/home",
    },
    {
      displayName: "Back Office",
      iconName: "settings"
    }
  ];
  @ViewChild("start") public sideBarMenu: MatSidenav;
  constructor(
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public configService: ConfigService,
    private dialog: MatDialog,
    public userService: UserService,
    public router: Router,
    private cart1Service: Cart1Service,
    public apiService: RestApiService,
    public utilityService: UtilityService,
  ) {
    this.userServiceModal = this.userService.userServiceModal;
    this.userServiceModal.selectedCountry = sessionStorage.getItem('selectedCountry') ;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.configService.commonSettings.CustomerTypes.forEach((ct) => {
      this.memberTypes.push({
        ID: ct.ID,
        typeName: ct.Description,
        iconName: ct.ID == 1 ? "check" : ct.ID == 2 ? "cart" : "star",
        typeDescription: this.descriptions[ct.ID],
      });
    });
    this.apiService.getActiveCountries().subscribe(data => {
      this.allowedCountries = data.Data || data;
      if(this.allowedCountries?.length > 0){
        this.allowedCountries = this.allowedCountries.filter(ac => ac.CountryCode?.toLowerCase() !== "jp");
      }
    })
    this.selectedCustomerType = this.memberTypes.filter(m => m.ID == (sessionStorage.getItem('selectedCustomerTypeID') ? sessionStorage.getItem('selectedCustomerTypeID') : 2))[0].typeName;
    this.content_display = this.customerTypeDescription();
  }

  ngOnInit() {
    setTimeout(() => {
      this.welcmtext = this.translate.instant("welcome_");
    }, 3000);

    this.activatedRoute.queryParams.subscribe((type: any) => {
      if(Object.keys(type).length != 0){
        this.token = decodeURIComponent(type.resetpasswordtoken)

        let request ={
          token:this.token
        }

        this.apiService.secondForgotPasswordProcess(request).subscribe((res)=>{
          if(res.status == 200){
            let customerid = res.data.CustomerID;
            let dialogData = {
              id:customerid,
              forNewPass:true
            }

            const dialogRef = this.dialog.open(ForgotDialogComponent, {
              data: dialogData,
              width: '360px',
              height: 'auto',
              panelClass: 'myapp-no-padding-dialog',
              autoFocus: false,
              disableClose: true
            });
          }else{
            console.log('error')
          }
        })
      }
    })
  }
  descriptions = {
    "3": "Receive discounted pricing on all products & earn free product through Loyalty Rewards + all the benefits of a Retail Customer.",
    "2": "Enjoy high quality Common Sense products & access to a personal account + personal website.",
    "1": "Earn commissions, bonuses, trainings, business tools, team onboarding + all the benefits of a Preferred customer.",
  };

  sildeMobileNav($event: any) {
    this.sideBarMenu.toggle();
  }

  public updatecountry(country, languagecode) {
    if(country.CountryCode == 'jp'){
      window.location.href = 'https://www.commonsenseww.jp/CommonSense/enroll/'
    }else{
      this.cart1Service.updateCountry(country, languagecode, false, false);
    }
  }

  navigateToChangeAffiliate() {
    this.dialog.open(JoiningdialogComponent, {
      disableClose: true,
      panelClass: "Joining-dialog",
      autoFocus: false
    });
  }

  changeCustomerType(ID) {
    if (ID === this.userServiceModal.customerTypeID) {
      return;
    }

    const dialogData = new ConfirmDialogModel(
      this.translate.instant("update_customer_title"),
      this.translate.instant("update_customer_text"),
      this.translate.instant("NO"),
      this.translate.instant("YES")
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.userServiceModal.customerTypeID = ID;
        sessionStorage.setItem("selectedCustomerTypeID", ID);
        this.router
          .navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { type: ID },
            queryParamsHandling: "merge",
          })
          .then(() => {
            window.location.reload();
          });
        this.cart1Service.clearCart();
      }
    });
  }

  close(item) {
    if (item.children && item.children.length) {
    } else {
      this.sideBarMenu.close();
    }
  }
  closed(val) {
    if (val == "1") {
    } else {
      this.sideBarMenu.close();
    }
  }

  customerTypeDescription() {
    if (!this.configService.commonSettings.CustomerTypes) {
      return "";
    }
    if (!this.userServiceModal.customerTypeID) {
      return "";
    }
    return _.find(
      this.configService.commonSettings.CustomerTypes,
      (customerType) => {
        return this.userServiceModal.customerTypeID == customerType.ID;
      }
    ).Description;
  }

  isLoggedIn() {
    if (
      Object.keys(this.userServiceModal.customerData).length &&
      this.userServiceModal.customerData.CustomerId &&
      this.userService.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }
}
