import { Component, OnInit } from '@angular/core';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';
import $ from 'jquery';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDialogComponentComponent } from '../login-dialog-component/login-dialog-component.component';
import { MatDialog } from '@angular/material/dialog';
import { MembershipDialogComponent } from '../membership-dialog/membership-dialog.component';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  userServiceModal: UserServiceModal;

  currentRouterPath: any;

  constructor(public activatedRoute: ActivatedRoute, public configService: ConfigService, public dialog: MatDialog, public router: Router, public userService: UserService, public accountService: AccountService) {

    this.userServiceModal = this.userService.userServiceModal;
    this.router.events.subscribe((event) => {
      this.currentRouterPath = router.url;
    });

  }
  ngOnInit() {
    $(window).bind('scroll', function (e) {
      if ($(window).width() < 1340) {
        return;
      }
      let Thewidth = window.visualViewport.width;
      if (Thewidth < 1650 && $('#sideCartBox')[0]?.clientHeight > 350) {
        if ($(window).scrollTop() + window.innerHeight >= $('#footer').offset().top) {

          if ($(window).scrollTop() + window.innerHeight < 2100) {

            $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '-200px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
          } else {

            $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '-360px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
          }
        } else {
          $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '70px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
        }
      } else if (Thewidth < 2200 && $('#sideCartBox')[0]?.clientHeight < 350) {

        if ($(window).scrollTop() + window.innerHeight >= $('#footer').offset().top) {
          if ($('#sideCartBox')[0]?.clientHeight < 350) {
            $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '-75px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
          } else {
            $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '-100px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
          }
        } else {
          $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '100px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '625px' })
        }
      }
    });
  }
  isLoggedIn() {
    if ((Object.keys(this.userServiceModal.customerData).length) && (this.userServiceModal.customerData.CustomerId) && this.userService.checkIfUserAuthenticatedOrNot()) {
      return true;
    } else {
      return false;
    }
  }
  logss() {
    window.open('https://commonsense.office2.directscalestage.com/#/Login','_blank');
    // const dialogRef = this.dialog.open(LoginDialogComponentComponent, {
    //   width: "600px",
    //   autoFocus: false
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    // });
  }
  logoutHandler() {

    this.accountService.logout();
  }

  shopDialog() {
    // if (this.isLoggedIn()) {
      this.router.navigate(['/shopnow']);
    // } else {
    //   const dialogRef = this.dialog.open(MembershipDialogComponent, {
    //     width: '800px',
    //     height: 'auto',
    //     panelClass: 'myapp-no-padding-dialog'
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //   });
    // }
  }

  goToJoin(type) {
    if (type == 1) {
      this.router.navigate(['/join'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { type },
          queryParamsHandling: 'merge'
        }).then(()=>{
          window.location.reload();
        })
    }
    else if (type == 3) {
      this.router.navigate(['/join'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { type },
          queryParamsHandling: 'merge'
        }).then(()=>{
          window.location.reload();
        })
    }
  }

}
