import { ApplicationInitStatus, APP_INITIALIZER, Component, HostListener, Inject, OnInit } from '@angular/core';
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd, ActivatedRoute, ActivationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from './components/shared/services/config.service';
import { UserService } from './components/shared/services/user.service';
import { PersistentService } from './components/shared/services/persistent.service';
import { Cart1Service } from './components/shared/services/cart1.service';
import { ProductService } from './components/shared/services/product.service';
import { SideNavBarService } from './components/shared/services/sidenavbar.service';
import { CompanyService } from './components/shared/services/company.service';
import { NotificationService } from './components/shared/services/notification.service';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  public url: any;
  public counter: number;

  constructor(
    @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus,
    public configService: ConfigService,
    private dialog: MatDialog,
    public appSettings: AppSettings,
    public router: Router,
    private translate: TranslateService,
    public userService: UserService,
    public persistent: PersistentService,
    public cartsService: Cart1Service,
    private route: ActivatedRoute,
    public itemsService: ProductService,
    public sideNavBarService: SideNavBarService,
    public companyService: CompanyService,
    public notification: NotificationService
  ) {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (sessionStorage.getItem("language")) {
      translate.setDefaultLang(sessionStorage.getItem("language"));
      translate.use(sessionStorage.getItem("language"));
    } else {
      translate.setDefaultLang("en");
      translate.use("en");
    }
    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.setItem("userService", JSON.stringify(this.userService.userServiceModal));
    localStorage.setItem("retailData", JSON.stringify(this.persistent.retailData));
  }

  ngOnInit() {
    this.linkBuilderProduct_AddtoCart();
    document.documentElement.style.setProperty("--theme-deafult", "#FFA500");
  }

  itemType: string;
  itemTypes = {
    pack: {
      getItemsCall: "GetEnrollmentKitItems",
      itemQuantity: "packQuantity",
      cartItems: "selectedPacks",
      itemserviceCall: "getPackItems",
    },
    order: {
      getItemsCall: "GetProducts",
      itemQuantity: "orderQuantity",
      cartItems: "selectedOrderItems",
      itemserviceCall: "getOrderItems",
    },
    autoship: {
      getItemsCall: "GetAutoshipItems",
      itemQuantity: "autoshipQuantity",
      cartItems: "selectedAutoOrderItems",
      itemserviceCall: "getAutoshipItems",
    },
  };

  itemsForCustomerIBO() {
    this.itemsService.getProduct(14).subscribe((p) => {
      //if length of selected initial items is not 0 then loops through cart to check item 14
      if (this.itemsService.selectedOrderItems.length != 0) {
        let alreadyinCart = false;
        this.itemsService.selectedOrderItems.forEach(item => {
          if (item.ItemID == 14) {
            alreadyinCart = true;
          }
        })
        if (!alreadyinCart) {
          this.cartsService.addToCart(p, false, "", false, false, false, true, false, this.counter);
        }
      }
      //if length of selected initial items is 0 then adds item 14 to cart
      else {
        this.cartsService.addToCart(p, false, "", false, false, false, true, false, this.counter);
      }
      //end
    });
  }

  itemsForCustomerPC() {
    this.itemsService.getProduct(23748).subscribe((p) => {
      if (this.itemsService.selectedOrderItems.length != 0) {
        let alreadyinCart = false;
        this.itemsService.selectedOrderItems.forEach(item => {
          if (item.ItemID == 23748) {
            alreadyinCart = true;
          }
        })
        if (!alreadyinCart) {
          this.cartsService.addToCart(p, false, "", false, false, false, true, false, this.counter);
        }
      }
      //if length of selected items is 0 then adds item 23748 to cart
      else {
        this.cartsService.addToCart(p, false, "", false, false, false, true, false, this.counter);
      }
      //end
    });
  }

  public addToCart(type, product: any, quantity) {
    this.currentQuantity(type, product, quantity);
    if(product){
      product.Quantity = quantity;
      this.cartsService.addToCart(product, true, product.ItemID, type == "autoship", false, type == "pack", false, false, this.counter);
      localStorage.setItem("params.item", JSON.stringify(product));
    }
  }
  currentQuantity(type, item, quantity) {
    if (type && item && quantity) {
      type = type || this.itemType;
      this.cartsService[this.itemTypes[type].itemQuantity] =
        this.cartsService[this.itemTypes[type].itemQuantity] || {}; // TODO::check if needed
      this.cartsService[this.itemTypes[type].itemQuantity][item.ItemID] =
        quantity;
    } else {
      this.notification.error('error', 'Please try again')
    }

  }

  //Added for link builder product cart adding, functionality integrate
  private linkBuilderProduct_AddtoCart() {
    try {
      this.router.events.subscribe((event) => {
        if (event instanceof ActivationStart) {
          if (Object.keys(event.snapshot.queryParams).includes("products")) {
            let parameters = event.snapshot.queryParams;
            let customerType = parameters.type;
            this.userService.userServiceModal.selectedCountry = parameters.countrycode;
            this.userService.userServiceModal.selectedLanguageCode = parameters.language;
            this.userService.userServiceModal.customerTypeID = parseInt(customerType, 10);
            localStorage.setItem('userService', JSON.stringify(this.userService.userServiceModal));
            sessionStorage.setItem('selectedCountry', this.userService.userServiceModal?.selectedCountry);
            let items = JSON.parse(parameters.products);
            sessionStorage.setItem('linkbuilderITEMS', JSON.stringify(items));
            this.counter = 0;
            this.itemsService.getProductByCategory().subscribe((res) => {
              if (items.length > 0) {
                items.forEach((item) => {
                  this.counter += item.autoshipqty;
                  if (item.orderqty >= 1) {
                    this.counter += 1;
                  }
                });
              }
              if (items.length > 0 && (this.userService.userServiceModal.customerTypeID == 3 || this.userService.userServiceModal.customerTypeID == 1)) {
                this.counter = this.counter + 1;
              }
              if (parameters.products) {
                JSON.parse(parameters.products).forEach((item) => {
                  this.itemsService.getProduct(item.itemcode).subscribe((product) => {
                    // order quantity items
                    if (item.orderqty > 0) {
                      if (item.itemoptionid > 0) {
                        product.OptionsMap.every(element => {
                          if (parseInt(element.ItemId) == parseInt(item.itemoptionid)) {
                            product.ItemID = element.ItemId;
                            const key = element.Key;
                            product.selectedOptions = '[' + key.replaceAll('|', ',') + ']';
                            this.addToCart('order', product, item.orderqty);
                            localStorage.setItem('cart.order', JSON.stringify(this.itemsService.selectedOrderItems));
                            return false
                          } else {
                            return true
                          }
                        });
                      } else {
                        this.addToCart('order', product, item.orderqty);
                      }
                    }
                    // order items end
                    // autoship quantity items
                    if (item.autoshipqty > 0) {
                      if (item.itemoptionid > 0) {
                        product.OptionsMap.every(element => {
                          if (parseInt(element.ItemId) == parseInt(item.itemoptionid)) {
                            product.ItemID = element.ItemId;
                            const key = element.Key;
                            product.selectedOptions = '[' + key.replaceAll('|', ',') + ']';
                            this.addToCart('autoship', product, item.autoshipqty);
                            return false
                          } else {
                            return true
                          }
                        });
                      } else {
                        this.addToCart('autoship', product, item.autoshipqty);
                      }
                    }
                    // autoship items end
                  });
                });
                // membership item
                if (this.userService.userServiceModal.customerTypeID == 3) {
                  this.itemsForCustomerPC();
                } else if (this.userService.userServiceModal.customerTypeID == 1) {
                  this.itemsForCustomerIBO();
                }
                // membership item end
                // this.router.navigate(['home'], {
                //   queryParams: {},
                //   queryParamsHandling: 'merge',
                // });
                // const currentPath = this.router.routerState.snapshot.url;
                //   console.log(currentPath);
                this.sideNavBarService.triggerOpen();
              }
              }
            );
          }
          // })
          // snapshot if ends
        }
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        try {
          let pathArray = event.urlAfterRedirects.split('/');
          let pageName = pathArray[pathArray.length - 1];
          var data = JSON.stringify({ "pageName": pageName, "pageURL": location.href });
          window['ZTM'].setStats('commonsense', 'PageView', data);
        } catch (error) {
        }
        // activation route end
      });
      // route subscribe ends
    } catch(error) {
      console.error("This error is from link builder integration")
    }
  }
}
