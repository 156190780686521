import { Component, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { CartItem } from "src/app/modals/cart-item";
import { CartService } from "../services/cart.service";
import { AppSettings, Settings } from "../services/color-option.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../services/account.service";
import { UserService } from "../services/user.service";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { Router } from "@angular/router";
import { RestApiService } from "../services/restapi.service";
import { LoginDialogComponentComponent } from "../login-dialog-component/login-dialog-component.component";
import { MatDialog } from "@angular/material/dialog";
import { MembershipDialogComponent } from "../membership-dialog/membership-dialog.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() sildeMobileNavEvent = new EventEmitter<any>();
  public currencies = ["USD", "EUR"];
  public currency: any;
  public flag: any;
  public userService: UserServiceModal;
  userServiceModal: UserServiceModal;
  products: Array<DSProduct>;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;
  isTop: boolean = true;
  currentRouterPath: any;

  constructor(
    private accountService: AccountService,
    private cartService: CartService,
    public appSettings: AppSettings,
    public translate: TranslateService,
    public user: UserService,
    public router: Router,
    public apiService: RestApiService,
    public UserService: UserService,
    public dialog: MatDialog
  ) {
    this.userServiceModal = this.UserService?.userServiceModal;
    this.settings = this.appSettings.settings;
    this.userService = this.user.userServiceModal;
    this.cartService.getItems().subscribe((shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems));
    this.router.events.subscribe((event) => { this.currentRouterPath = router.url; });
  }

  ngOnInit() {
    this.currency = this.currencies[0];
  }
  toggleMobileMenu($event) {
    this.sildeMobileNavEvent.emit($event);
  }
  changeCurrency(currency) {
    this.currency = currency;
  }
  changeLang(flag) {
    this.flag = flag;
  }
  logout() {
    this.accountService.logout();
  }
  login() {
    window.open('https://commonsense.office2.directscalestage.com/#/Login','_blank');
    // this.dialog.open(LoginDialogComponentComponent, {
    //   width: "600px",
    //   autoFocus: false
    // })
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(): void {
    if (window.scrollY > 60) {
      this.isTop = false;
    } else {
      this.isTop = true;
    }
  }
  isLoggedIn() {
    if (
      Object.keys(this.userService.customerData).length &&
      this.userService.customerData.CustomerId &&
      this.user.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }
  backoffice() {
    let windowReference = window.open();
    let request = {
      CustomerID: this.userService.customerData.CustomerId,
      Dest: 'home'
    }
    this.apiService.getCustomerSSOURL(request).subscribe((result) => {
      windowReference.location = result.Data
    })
  }
  openDialogs() {
      this.router.navigate(['/shopnow']);
  }
}
