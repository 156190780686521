// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ************ comment it when deploy**********
  // apiUrl: 'https://neumiapi-stage.ziplingo.com/',
  // productUrl: 'https://neumiapi-stage.ziplingo.com/',
  // imageUrl: 'https://neumi.corpadmin.directscale.com/CMS/Images/Inventory'

  // ************ uncomment it when deploy**********
  // apiUrl: 'https://commonsenseapi-prod.ziplingo.com/' ,
  // productUrl: 'https://commonsenseapi-prod.ziplingo.com/',
  // imageUrl: 'https://commonsense.corpadmin.directscale.com/CMS/Images/Inventory'

  apiUrl: 'https://commonsenseapi.ziplingo.com/' ,
  productUrl: 'https://commonsenseapi.ziplingo.com/',
  imageUrl: 'https://commonsense.corpadmin.directscalestage.com/CMS/Images/Inventory'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
